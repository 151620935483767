import React from "react";
import HomeSuzukiCard from "./homeSuzukiCard";
import { configuration } from "../../helpers/config";

// Tab components for find perfect suzuki section on homepage.
export default function TypeTabs({ data, modelsData }) {
  return (
    <div className="homeSuzukiCard--container">
      {data?.map((item, i) => {
        // filter cars data according to id
        const carData = modelsData?.filter((car) => car?._id === item?._id)[0];
        // display individual car card with price , image and other relevant info
        // ptopic.log("carss",carData)

        return (
          carData && (
            <HomeSuzukiCard
              key={i}
              Id={"HomePage" + (carData?.model_code)?.replace(/\s+/g, "")}
              modelCode={carData?.model_code}
              cardClassName={""}
              imgSrc={`${configuration.IMG_URL}${carData?.images?.desk_small}`}
              alt_text={carData?.images?.desk_small_alt}
              cardTitle={carData?.model_name}
              cardItemPrice={carData?.retail_price}
              itemFuelType={carData?.fuel_type?.toUpperCase()}
              itemSeater={carData?.vehicle_type}
              cardLink={carData?.page_url}
              offer_price={carData?.price4}
              rable_price={carData?.price3}
              price={carData?.price2}
              car_page_price_one={carData?.car_page_price_one}
              car_page_price_two={carData?.car_page_price_two}
              car_page_price_three={carData?.car_page_price_three}
              car_page_pricetext_two={carData?.car_page_pricetext_two}
              car_page_pricetext_three={carData?.car_page_pricetext_three}
              additionalInformation={carData?.additionalInformation}
            />
          )
        );
      })}
    </div>
  );
}
