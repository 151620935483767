import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TypeTabs from "./typeTabs";
import axios from "axios";
import { configuration } from "../../helpers/config";
import TermsAndConditionsModal from "./terms&ConditionsModal";
import { Constants } from "../../helpers/constants";
export default function FindPerfectSuzuki({ sectionHeading, carsData,modelsData }) {
  // const [carsData, setCarsData] = useState([]);
  // const [modelsData, setModelsData] = useState([]);
  const [isTermsModalVisible, setTermsModalVisibility] = useState(false);
  const LOB_VALUE=useSelector(state=>state?.app?.LobValue);

  // function to fetch all models and assign to modelsData state
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await axios.get(
  //         `${configuration.APP_URL_PRODUCTS}model?lob=${LOB_VALUE}`,
  //       );
  //       setModelsData(res.data.data);
  //     } catch (e) {
  //       // console.log(e.message);
  //     }
  //   })();
  // }, [LOB_VALUE]);

  // matching and assigning model data to respective _ids from props
  // useEffect(() => {
  //   const newData = [];
  //   for (const [key, value] of Object.entries(cars)) {
  //     key !== "_id" && newData.push({ ["name"]: key, ["data"]: value });
  //   }
  //   setCarsData(newData);
  // }, [cars]);


  return (
    <div className="perfectSuzuki__container">
      <div className="container mx-auto">
        <h3 className="perfectSuzuki__container--sectionHeading">
          {sectionHeading}
        </h3>
      </div>
      <div className="perfectSuzuki__container--tabs container-with-padding">
        <Tabs>
          <TabList>
            {/*render tabs of cars */}
            {carsData?.map(
              (item, i) =>
                item.cars?.length > 0 && (
                  <Tab key={i}>{item?.title}</Tab>
                ),
            )}
          </TabList>

          {/*render cars according to selected tab*/}
          {carsData?.map(
            (item, i) =>
              item.cars?.length > 0 && (
                <TabPanel key={i}>
                  <div className="container mx-auto">
                    <TypeTabs data={item?.cars} modelsData={modelsData?.data} />
                  </div>
                </TabPanel>
              ),
          )}
        </Tabs>
        {/*provision to show tnc in this section*/}
        {configuration.SHOW_TNC_FIND_SUZUKI === "true" && (
          <div
            className="mt-10 ml-4 md:ml-0 underline text-[#3366CC] cursor-pointer w-fit"
            onClick={() => setTermsModalVisibility(true)}
          >
            {Constants.findSuzuki.TERMS_AND_CONDITIONS}*
          </div>
        )}
      </div>
      {isTermsModalVisible && (
        <TermsAndConditionsModal
          open={isTermsModalVisible}
          onClose={() => setTermsModalVisibility(false)}
        />
      )}
    </div>
  );
}
